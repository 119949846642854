<template>
  <div class="DriverEntityDetails__Component">
    <FieldInlineText
      label="מספר טלפון"
      @onChange="onChange"
      :read-only="readOnly"
      v-model="model.PhoneNumber"
    />
    <FieldInlineText
      label="ת.ז"
      @onChange="onChange"
      :read-only="readOnly"
      v-model="model.IdNumber"
    />

    <FieldInlineText
      label="שם מלא"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.Name"
    />
    <FieldInlineText
      label="מייל"
      @onChange="onChange"
      v-model="model.Email"
      :readOnly="readOnly"
      :type="'email'"
    />

    <FieldInlineText
      label="מספר רישיון נהיגה"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.LicenseNumber"
    />

    <FieldInlineSelect
      label="מנהל"
      :filterable="false"
      :clearable="false"
      :optionsAsync="getDrivers"
      v-model="model.ManagerId"
      :null-is-value="true"
      @onChange="onChange"
      :readOnly="readOnly"
      required="true"
    />

    <FieldInlineText
      label="קוד מערכת ניתור"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.TrackerDriverId"
    />

    <!-- <FieldInlineText
      label="עיר"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.City"
    />

    <FieldInlineText
      label="רחוב"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.Street"
    /> -->

    <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="עיר" :clearable="true" :filterable="true"
      :null-is-value="true" placeholder="הקלד עיר" :options="[]" :fetch-options="onSearchCities"
      @onChange="onChange" :reduce="(item) => item.Text" v-model="model.City" />

    <FieldInlineSelect :readOnly="!model.City" :alert-not-exist="true" :hide-hover="true" label="רחוב" :clearable="true" :filterable="true"
      :null-is-value="true" placeholder="הקלד רחוב" :options="[]" :fetch-options="onSearchCityAddress"
      @onChange="onChange" :reduce="(item) => item" v-model="model.Street" />

    <FieldInlineText
      label="מספר בית"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.HouseNumber"
    />

    <FieldInlineText
      label="מיקוד"
      @onChange="onChange"
      :readOnly="readOnly"
      v-model="model.ZipCode"
    />
  </div>
</template>

<script>
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import DriverService from "@/services/DriverService";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DriverEntityDetails",
  components: {
    FieldInlineText,
    FieldInlineSelect,
  },
  props: ["readOnly"],
  data() {
    return {
      model: {
        ManagerId: null,
        TrackerDriverId: null,
        Street: null,
        HouseNumber: null,
        City: null,
        IdNumber: null,
        Email: null,
        LicenseNumber: null,
        ZipCode: null,
        Name: null,
        PhoneNumber: null,
        DriverNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters("driver", ["form"]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === "function") {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) {
            return diff1;
          }
        }
        if (typeof o1[key] === "number") {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (
          o1[key] &&
          typeof o1[key] === "object" &&
          typeof o1[key].getMonth !== "function"
        ) {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    getDrivers() {
      return DriverService.get;
    },
    onChange() {
      this.$emit("onFormChange", this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit("onFormSave", {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeDriverDetails(this.model);
    },
    onSearchCities({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCity(1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
              this.model.Street = null;
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onSearchCityAddress({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCityAddress(this.model.City, 1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations("driver", ["storeDriverDetails"]),
  },
};
</script>

<style>
</style>
